import React from 'react';
import './SoftwareSkill.scss';
import { skillsSection } from '../../portfolio';

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            const { Icon } = skills;
            return (
              <li key={i} className="software-skill-inline" name={skills.skillName}>
                <Icon />
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
